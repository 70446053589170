import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from 'react-router-dom';
import Home from "./Components/Home";
import HowItWorks from "./Components/HowItWorks";
import CardDetailPage from "./CardDetailPage";
import Contact from "./Contact";
import Footer from "./Footer";
import SmallCardDetailPage from "./SmallCardDetailPage";
import AboutUs from "./AboutUs";
import TermsAndCondition from "./TermsAndCondition";
import ReadMorePage from "./ReadMorePage";
import BookSession from "./BookSession";
import BookSesionForm from "./BookSesionForm";
import PaymentStatus from "./PaymentStatus";
import Registration from "./Registration";

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};

const Main = () => {
  const location = useLocation();
console.log(location.pathname)
  return (
    <>
      {/* You can add a navigation bar here */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/:description" element={<CardDetailPage />} />
        <Route path="/payment-status/:status" element={<PaymentStatus />} />
        <Route path="/detail/:id" element={<SmallCardDetailPage />} />
        <Route path="/for-australian-school" element={<ReadMorePage />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/book-session/:url" element={<BookSesionForm />} />
        {/* Add more routes as needed */}
      </Routes>

      {/* Conditionally render Footer */}
      {(location.pathname !== "/payment-status/Completed" && location.pathname !== "/payment-status/Failed") && <Footer />}
    </>
  );
};

export default App;
