import React, { useState } from "react";
import { Carousel, Container, Row, Col, Form, Button } from "react-bootstrap";
import "./Testimonial.css"; // Custom styles
import BackgroundImage from "./images/email-bg.png";
const Emailus = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the subscription logic here
    console.log("Email submitted:", email);
    // Clear the input after submission
    setEmail("");
  };
  return (
    <div
      className="subscription-section"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <Container className={`text-center ${window.innerWidth >= 768 ? 'py-5' : 'pt-5'}`}>
        <Row>
          <Col>
            <h2 style={{ color: "#2B2C31" }}>
              Subscribe to hear of our upcoming experiences
            </h2>
            <Form
              inline
              onSubmit={handleSubmit}
              className="subscription-form mt-3"
            >
              <Form.Control
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="subscription-input"
              />
              <Button type="submit" className="subscription-button">
                Subscribe
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Emailus;
