import React, { useState } from "react";
import { Navbar, Nav, Container, Row, Col, Offcanvas } from "react-bootstrap";
import "./Navbar.css"; // Import custom CSS for background
import logo from "./images/logo.png"; // Update this path
import { Link } from "react-router-dom"; // Import Link

function NavbarPage() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  return (
    <div className="background-image">
      <Navbar expand="lg" variant="dark" className="transparent-navbar">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleShow}
          />
          <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
            <Nav className="ms-auto nav-links">
              <Nav.Link href="/">HOME</Nav.Link>
              <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
              <Nav.Link href="/contact">CONTACT</Nav.Link>
              <Nav.Link href="/registration">REGISTRATION</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Offcanvas for Small Screens */}
      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {" "}
            <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link href="/" onClick={handleClose}>
              HOME
            </Nav.Link>
            <Nav.Link href="/how-it-works" onClick={handleClose}>
              HOW IT WORKS
            </Nav.Link>
            <Nav.Link href="/contact" onClick={handleClose}>
              CONTACT
            </Nav.Link>
            <Nav.Link href="/registration" onClick={handleClose}>
              REGISTRATION
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Video and Text Section */}
      <Container className="video">
        <Row>
          {/* Text Section */}
          <Col md={6} className="text-left">
            <h1>
              {/* Live, interactive sessions between school classrooms from different countries */}
              Empowering schools and teachers to deliver live and interactive
              in-class learning experiences to students that strengthen their
              understanding of the world.
            </h1>
            <Link to="/how-it-works" className="btn btn-primary btn mt-3">
              How It Works
            </Link>
          </Col>

          {/* Video Section */}
          <Col md={6}>
            <div className="video-wrapper">
              <iframe
                width="590"
                height="415"
                src="https://www.youtube.com/embed/YyS0CGoMKY8?si=2_udT_trsP41S20A"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NavbarPage;
